.mcarthur {
    --ion-color-primary: #e98300;
    --ion-color-primary-rgb: 233, 131, 0;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #cd7300;
    --ion-color-primary-tint: #eb8f1a;

    --ion-color-secondary: #6c6f70;
    --ion-color-secondary-rgb: 108, 111, 112;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #5f6263;
    --ion-color-secondary-tint: #7b7d7e;
}
