.symmetry-hr {
    --ion-color-primary: #439cb6;
    --ion-color-primary-rgb: 67, 156, 182;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #78889a;
    --ion-color-primary-tint: #94a5b7;

    --ion-color-secondary: #82a929;
    --ion-color-secondary-rgb: 130, 169, 41;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #4aa5a7;
    --ion-color-secondary-tint: #65c3c5;
}
