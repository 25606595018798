.ele {
    --ion-color-primary: #00994b;
    --ion-color-primary-rgb: 233, 131, 0;
    --ion-color-primary-contrast: #fff;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #00994b;
    --ion-color-primary-tint: #00994b;

    --ion-color-secondary: #424241;
    --ion-color-secondary-rgb: 108, 111, 112;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #424241;
    --ion-color-secondary-tint: #424241;
}
