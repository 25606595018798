/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import 'swiper/css';

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

$disabled-opacity: 0.7;

* {
    font-family: Metropolis, 'Avenir Next', 'Helvetica Neue', Arial, sans-serif;
}

html {
    font-size: 16px;

    @media only screen and (max-width: 400px) {
        body {
            font-size: 14px;
        }
    }
}

ul,
ol {
    list-style-position: inside;
    margin-left: 0;
    margin-top: 8px;
    margin-bottom: 0;
    padding-left: 0;
}

//TODO: Сделано для решения проблемы на устройствах с высоким разрешением DRA-61 и для galaxy S6?
.picker-opt {
    overflow: visible;
    min-width: 40px;
    font-size: 0.9rem;
}

@import '~leaflet/dist/leaflet.css';

h1,
h2,
h3,
h4 {
    color: unset;
}

.saved-filters {
    --background: var(--ion-color-medium-shade);
}

.signature-modal {
    --border-radius: 4px;

    ion-header ion-toolbar:first-of-type {
        padding-top: 0;
    }
}

.sc-ion-textarea-ios-h,
.sc-ion-input-ios-h {
    --padding-start: 4px;
}

ion-header {
    background-color: var(--ion-background-color);
}

ion-select {
    &::part(container) {
        width: 100%;
    }

    &.select-disabled {
        opacity: $disabled-opacity;
    }

    --highlight-color-valid: var(--ion-color-primary) !important;
    --highlight-color-focused: var(--ion-color-primary) !important;
    --highlight-color-invalid: var(--ion-color-primary) !important;
    --highlight-color-focused: var(--ion-color-primary) !important;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
}

ion-textarea {
    --highlight-color-valid: var(--ion-color-primary) !important;
    --highlight-color-focused: var(--ion-color-primary) !important;
    --highlight-color-invalid: var(--ion-color-primary) !important;
    --highlight-color-focused: var(--ion-color-primary) !important;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
}

ion-input {
    &[disabled].input-disabled {
        opacity: $disabled-opacity;
    }

    --highlight-color-valid: var(--ion-color-primary) !important;
    --highlight-color-focused: var(--ion-color-primary) !important;
    --highlight-color-invalid: var(--ion-color-primary) !important;
    --highlight-color-focused: var(--ion-color-primary) !important;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
}

ion-modal.modal-default.date-time {
    --backdrop-opacity: 0.3 !important;
}

ion-footer {
    background-color: #fff;
    padding-bottom: env(safe-area-inset-bottom);
}

@media (min-width: 992px) {
    ion-header,
    ion-title,
    ion-content,
    ion-footer {
        max-width: 600px;
        margin: 0 auto !important;
        align-self: center;
    }

    ion-content {
        /* -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); */
        box-shadow:
            0 2px 4px -1px rgba(0, 0, 0, 0.2),
            0 4px 5px 0 rgba(0, 0, 0, 0.14),
            0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    ion-menu {
        --width: 400px !important;
    }

    .menu-inner {
        max-width: 600px;
        margin: 0 auto !important;
        position: relative;
    }
}
