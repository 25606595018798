.enterprise {
    --ion-color-primary: #ed1b34;
    --ion-color-primary-rgb: 237, 27, 52;
    --ion-color-primary-contrast: #fff;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #ed1b34;
    --ion-color-primary-tint: #ed1b34;

    --ion-color-secondary: #1e3d55;
    --ion-color-secondary-rgb: 30, 61, 85;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #1e3d55;
    --ion-color-secondary-tint: #1e3d55;
}
