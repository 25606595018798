@use './../../../../node_modules/@ionic/core/css/utils.bundle.css';

.alliancenursing {
    --ion-color-primary: #007dc3;
    --ion-color-primary-rgb: 0, 125, 195;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #006eac;
    --ion-color-primary-tint: #1a8ac9;

    --ion-color-secondary: #7ed3f6;
    --ion-color-secondary-rgb: 126, 211, 246;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #6fbad8;
    --ion-color-secondary-tint: #8bd7f7;

    --ion-color-tertiary: #f5fcfe;
    --ion-color-tertiary-rgb: 245, 252, 254;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #d8dee0;
    --ion-color-tertiary-tint: #f6fcfe;

    --ion-color-danger: #d45c27;
    --ion-color-danger-rgb: 212, 92, 39;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #bb5122;
    --ion-color-danger-tint: #d86c3d;

    --ion-font-family: Roboto, Geneva, sans-serif;
}

.alliancenursing {
    --actassa-icon-font-size: 24px;

    ion-header {
        &::after {
            content: '';
            display: block;
            position: absolute;
            background-image: url(/assets/alliance-nursing/images/strip.png);
            background-size: cover;
            height: 4px;
            width: 100%;
        }
    }

    .header-ios ion-toolbar:last-of-type {
        --border-width: 0;
    }

    ion-menu-button,
    ion-back-button {
        --color: var(--ion-color-primary);
        --icon-font-size: var(--actassa-icon-font-size);

        &.ios {
            --icon-padding-start: calc(16px + var(--ion-safe-area-left, 0px));
            --icon-padding-stop: 16px;
        }
    }

    ion-title {
        --color: var(--ion-color-dark);
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
    }

    ion-item {
        --highlight-color-valid: var(--ion-color-primary);

        &[button] {
            --background: var(--ion-color-tertiary);
        }
    }

    ion-label {
        font-size: 16px;
        line-height: 19px;

        &.label-stacked {
            transform: scale(1);
        }
    }

    ion-text {
        font-size: 14px;
        line-height: 17px;
    }

    ion-button {
        --border-radius: var(--actassa-border-radius) !important; // TODO KB
        --box-shadow: none;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        outline: none;
        text-transform: capitalize;

        & * {
            font-weight: 500;
        }

        &.button-medium {
            height: 40px;
        }
    }

    ion-icon {
        font-size: var(--actassa-icon-font-size);
    }

    form {
        ion-list {
            --ion-item-background: transparent;
        }

        ion-item {
            --border-color: var(--ion-color-secondary);
            --background: transparent;
        }

        ion-checkbox {
            --border-color: var(--ion-color-secondary);
            --border-color-checked: var(--ion-color-primary);
            --background-checked: var(--ion-color-primary);
        }

        ion-radio {
            --color: var(--ion-color-secondary);
            --color-checked: var(--ion-color-primary);
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: Montserrat, Geneva, sans-serif !important;
    }

    h1 {
        font-weight: 900;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 700;
    }

    // specific styles for module functionality

    actassa-list-time {
        --actassa-list-time-shift-icon: 'assets/nswbc/icons/shift.svg';
    }

    actassa-main-menu .divider {
        border-bottom: 0;
        height: 2px;
        background-image: url(/assets/alliance-nursing/images/strip.png);
        background-size: cover;
    }

    app-home ion-content {
        --background: url('/assets/alliance-nursing/images/corner.svg') bottom right no-repeat #fff;
        --padding-bottom: 90px;
    }
}
