:root {
    --actassa-border-radius: 20px;

    --ion-color-primary: #307a8b;
    --ion-color-primary-rgb: 48, 122, 139;
    --ion-color-primary-contrast: #fff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #2a6b7a;
    --ion-color-primary-tint: #458797;

    --ion-color-secondary: #55cec2;
    --ion-color-secondary-rgb: 85, 206, 194;
    --ion-color-secondary-contrast: #000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #4bb5ab;
    --ion-color-secondary-tint: #66d3c8;

    --actassa-border-radius: 20px;

    --ion-font-family: Verdana, Geneva, sans-serif;
}

// TODO KB check in other places
h2 {
    font-size: 16px !important;
    line-height: 19px !important;
}

// TODO KB check in other places
h3 {
    font-size: 14px !important;
    line-height: 17px !important;
}

ion-item {
    &[button] {
        --background: var(--ion-color-light);
        --background-opacity: 1;
        --detail-icon-color: var(--ion-color-primary);
        --detail-icon-opacity: 1;
        border-radius: var(--actassa-border-radius); // TODO KB shold be used default css var
    }
}
