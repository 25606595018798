.ttm {
    --ion-color-primary: #889baf;
    --ion-color-primary-rgb: 136, 155, 175;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #78889a;
    --ion-color-primary-tint: #94a5b7;

    --ion-color-secondary: #54bcbe;
    --ion-color-secondary-rgb: 84, 188, 190;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #4aa5a7;
    --ion-color-secondary-tint: #65c3c5;

    --ion-color-ttm-blue: #6d8de1;
    --ion-color-ttm-blue-rgb: 109, 141, 225;
    --ion-color-ttm-blue-contrast: #fff;
    --ion-color-ttm-blue-contrast-rgb: 255, 255, 255;
    --ion-color-ttm-blue-shade: #607cc6;
    --ion-color-ttm-blue-tint: #7c98e4;

    --ion-color-ttm-blue-light: #e0e9ff;
    --ion-color-ttm-blue-light-rgb: 224, 233, 255;
    --ion-color-ttm-blue-light-contrast: #000000;
    --ion-color-ttm-blue-light-contrast-rgb: 0, 0, 0;
    --ion-color-ttm-blue-light-shade: #c5cde0;
    --ion-color-ttm-blue-light-tint: #e3ebff;

    --ion-color-ttm-blue-active: #9eacd0;
    --ion-color-ttm-blue-active-rgb: 158, 172, 208;
    --ion-color-ttm-blue-active-contrast: #ffffff;
    --ion-color-ttm-blue-active-contrast-rgb: 255, 255, 255;
    --ion-color-ttm-blue-active-shade: #8b97b7;
    --ion-color-ttm-blue-active-tint: #a8b4d5;
}

.ion-color-ttm-blue {
    --ion-color-base: var(--ion-color-ttm-blue);
    --ion-color-base-rgb: var(--ion-color-ttm-blue-rgb);
    --ion-color-contrast: var(--ion-color-ttm-blue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-ttm-blue-contrast-rgb);
    --ion-color-shade: var(--ion-color-ttm-blue-shade);
    --ion-color-tint: var(--ion-color-ttm-blue-tint);
}

.ion-color-ttm-blue-light {
    --ion-color-base: var(--ion-color-ttm-blue-light);
    --ion-color-base-rgb: var(--ion-color-ttm-blue-light-rgb);
    --ion-color-contrast: var(--ion-color-ttm-blue-light-contrast);
    --ion-color-contrast-rgb: var(--ion-color-ttm-blue-light-contrast-rgb);
    --ion-color-shade: var(--ion-color-ttm-blue-light-shade);
    --ion-color-tint: var(--ion-color-ttm-blue-light-tint);
}

.ion-color-ttm-blue-active {
    --ion-color-base: var(--ion-color-ttm-blue-active);
    --ion-color-base-rgb: var(--ion-color-ttm-blue-active-rgb);
    --ion-color-contrast: var(--ion-color-ttm-blue-active-contrast);
    --ion-color-contrast-rgb: var(--ion-color-ttm-blue-active-contrast-rgb);
    --ion-color-shade: var(--ion-color-ttm-blue-active-shade);
    --ion-color-tint: var(--ion-color-ttm-blue-active-tint);
}
