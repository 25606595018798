.trn {
    --ion-color-primary: #cd4242;
    --ion-color-primary-rgb: 205, 66, 66;
    --ion-color-primary-contrast: #fff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #b43a3a;
    --ion-color-primary-tint: #d25555;

    --ion-color-secondary: #e8757a;
    --ion-color-secondary-rgb: 232, 117, 122;
    --ion-color-secondary-contrast: #000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #cc676b;
    --ion-color-secondary-tint: #ea8387;

    --ion-color-danger: #ff6a00;
    --ion-color-danger-rgb: 255, 106, 0;
    --ion-color-danger-contrast: #fff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e05d00;
    --ion-color-danger-tint: #ff791a;

    --ion-font-family: Verdana, Geneva, sans-serif;
}
